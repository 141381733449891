/* Main Div Content */

.main {
  position: relative;
  width: 100%;
}

/* Resize main div content based on nav menu */

.main.collapsed {
  width: 100vw;
  left: 200px !important;
  transition: all 0.8s ease;
}

.main.expanded {
  width: 100vw;
  left: 100px !important;
  transition: all 0.8s ease;
}

/* Sidebar nav */

.pro-sidebar.collapsed {
  width: 100px;
  min-width: 100%;
}

.pro-sidebar {
  width: 200px;
  min-width: 100%;
}

#menu {
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}

.main-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
}


#top {
  top: 0;
  height: 2px;
  width: 100%;
}

/* Media Queries */
@media(max-width: 1300px) {
  .pro-sidebar {
    display:none;
  }
  .main.expanded {
    left: 0!important;
  }

  a#canvasnofun {
    display:none;
  }

  #head-contact-button {
    margin: 3rem 0.2rem 0.1rem 7rem;
  }
}