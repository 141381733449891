/* Home Main Div */
.home-main {
  width: 100vw;
}

/* Hero Section */
.banner {
  position: relative;
  display: flex;
  padding: 5em 2rem 2rem 2rem;
  width: 100%;
  height: 90vh;
}
.banner #home {
  min-height: 100vh;
}

#head-contact-button {
  padding: 1rem;
  margin: 3rem 0.2rem 0.1rem 4rem;
  background-color: var(--background-grey);
  color: var(--color-nice-white);
  border: 1px solid;
  font-weight: 600;
  z-index: 1;
  transition: 0.5s ease-in-out;
}

#head-contact-button::before {
  content: "";
  background-color: var(--background-grey);
  right: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.2s ease-in-out;
  z-index: -1;
}

#head-contact-button:hover,
#head-contact-button:focus {
  background-color: var(--color-btn-primary);
}

/* Typography */
h1 {
  position: relative;
  font-size: 5rem;
  color: var(--color-nice-white);
}

h2 {
  font-size: 3rem;
  color: var(--color-nice-white);
}

h3 {
  position: relative;
  font-size: 3.5rem;
  color: var(--color-nice-white);
}

p {
  font-size: 1.2rem;
  color: var(--color-nice-white);
}

.SplitText {
  display: inline-block;
  font-size: 5rem;
  color: var(--color-nice-white);
}

#sub-head {
  padding-top: 1.5rem;
}

/* Flipper */
@keyframes flip {
  0% {
    top: 0;
    animation-timing-function: ease-out;
  }

  22% {
    top: 30px;
    animation-timing-function: ease-out;
  }
  50% {
    top: 20px;
    animation-timing-function: ease-out;
  }
  100% {
    top: 0;
  }
}

/* Color Text Shift */

@keyframes colorshift {
  0% {
    color: rgba(236, 236, 236, 0.89);
  }
  25% {
    color: red;
  }

  75% {
    color: #ff7474e3;
  }

  100% {
    color: rgba(236, 236, 236, 0.89);
  }
}

.flipper {
  animation: flip 2s, colorshift 2s;
  -moz-animation: flip 2s, colorshift 2s;
  -webkit-animation: flip 2s, colorshift 2s;
}

/* About Me Banner */
.aboutme-banner {
  position: relative;
  padding: 5em 2rem 2rem 2rem;
  width: 100%;
  height: 90vh;
}

.about-heading {
  height: 200px;
  max-height: 35vh;
  color: var(--color-nice-white);
  border: 1px solid transparent;
  border-radius: 1rem;
  text-align: center;
  vertical-align: middle;
  margin: 2rem;
  padding: 3rem;
  box-shadow: 0px 12px 18px -6px;
}

#AboutMe {
  max-width: 50%;
  margin: auto;
  margin-left: 20vw;
  transition: transform 0.8s linear;
  z-index: 2;
}

#AboutMe:hover {
  transform: translateY(-7px);
}

a#canvasfun {
  background-color: white;
  position: fixed;
  width: 6rem;
  height: 3rem;
  top: 6rem;
  right: -25px;
  z-index: 10;
  padding: 35px;
  border-radius: 25%;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  color: var(--color-background-grey);
  display: flex;
  align-items: center;
}

a#canvasnofun {
  background-color: white;
  position: fixed;
  width: 6rem;
  height: 3rem;
  top: 6rem;
  right: -25px;
  z-index: 10;
  padding: 35px;
  border-radius: 25%;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  color: var(--color-background-grey);
  display: flex;
  align-items: center;
}

#particle-front {
  height: 1000vh;
}

/* Awards */
.awards-banner {
  position: relative;
  padding: 5em 2rem 2rem 6rem;
  width: 100%;
}

#Awards {
  max-width: 100%;
  width: 80vw;
  margin: auto;
  margin-left: 0;
  transition: transform 0.8s linear;
  z-index: 2;
  border: solid 1px;
  color: white;
  background-color: #121212;
  right: 0;
  display: block;
}

.awards-heading {
  color: var(--color-nice-white);
  margin: 2rem;
  padding: 3rem;
}

.awards-content {
  width: 100%;
  display: flex;
  padding-left: 6em;
  padding-bottom: 10rem;
}

.awards-content .col {
  width: 44%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 200px;
}

img {
  max-width: 300px;
  max-height: 300px;
  margin: 50px;
}

img:after {
  content: "Hermes";
}

/* Media Queries */
@media (max-width: 1024px) {
  .aboutme-banner {
    height: auto;
  }
  .banner {
    padding: 15em 2em 2em 2em;
  }

  .about-heading {
    margin: auto;
    margin-bottom: 4rem;
  }
  #AboutMe {
    max-width: 100%;
    margin-left: 0;
  }
  .awards-banner {
    padding: 0;
  }
  #Awards {
    width: 100%;
    display:flex;
    flex-direction: column;
  }
  .awards-content {
    display:block;
    padding: 0;

  }
  .awards-heading {
    padding: 3rem 3rem 3rem 6rem;
  }

  .awards-content .col {
    width: 100%;
  }
}
