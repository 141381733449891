@import url("https://fonts.googleapis.com/css2?family=Kosugi+Maru&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

body {
  background: var(--background-grey);
  font-family: "Kosugi Maru", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --background-grey: #121212;
  --color-off-white: #b5b5b5;
  --color-btn-primary: #203072;
  --color-btn-secondary: #283b8b;
  --color-nice-white: rgba(236, 236, 236, 0.89);
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

::-webkit-scrollbar {
  display: none;
}

/* ======== SECTIONS AND CONTAINERS ======= */
.container {
  display: flex;
  align-items: center;
}

/* ======== TYPOGRAPHY ======= */
h1,
h2,
h3,
h4 {
  font-weight: 500;
}

h2 {
  padding-bottom: 3rem;
}

section > h1 {
  color: var(--color-off-white);
}

section > h2 {
  color: var(--color-off-white);
}

p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 6px;
  line-height: 2.5em;
  margin: 14px 0 75px 0;
}

a {
  color: #283b8b;
}

a:hover {
  color: var(--color-btn-primary);
}

/* ======== BUTTONS ========= */
.btn {
  width: 100%;
  display: inline-block;
  background-color: var(--color-btn-primary);
  color: #fff;
  padding: 0.75rem 1.2rem;
  cursor: pointer;
  transition: all 200ms ease-in;
}

.btn:hover {
  background-color: var(--color-btn-secondary);
}

/* ========= MEDIA QUERIES ========= */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
}

/* ========= LISTS ========= */

li {
  color: #fff;
  font-size: 1.2rem;
  line-height: 5rem;
  text-decoration: none;
  text-decoration-color: var(--color-btn-primary);
}

ul {
  padding-top: 65px;
}
