/* Skills Section */

.skill {
  height: 100vh;
  width: 100%;
  padding: 6em 6em 50px 6em;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding-right: 9%;
  padding-top: 90px;
}

.skill-header {
  border-color: white;
  text-align: center;
  padding: 60px 50px;
}

.skill-box {
  padding: 60px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.skill h5 {
  font-size: 45px;
  font-weight: 700;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skills-list:after {
  content: "";
  position: absolute;
  bottom: -14px;
  left: 0;
  width: 70px;
  height: 5px;
  background-color: var(--color-btn-primary);
}

/* Media Queries */
@media (max-width: 1300px) {
  .skill {
    padding: 0;
  }

  .skill-header {
    padding: 5rem 0px;
  }

  .skill-box {
    display: block;
    padding: 0;
  }

  .skill-box .col {
    padding-bottom: 4rem;
  }
}
