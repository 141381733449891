.contact {
  width: 100%;
  padding: 15rem 2em 0 2em;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding-right: 9%;
}

form {
  display: block;
  width: inherit;
  line-height: 3;
  padding: 6rem;
}

form > .col {
  padding: 1em;
  width: 50%;
  display: inline-block;
}

label {
  display: block;
  color: white;
  margin-bottom: 0.6rem;
  font-size: 2rem;
}

input#name {
  width: 100%;
  height: 4rem;
  border: 1px solid transparent;
  border-radius: 15px;
  font-size: 2rem;
  padding: 1.3rem;
}

input#email {
  width: 100%;
  height: 4rem;
  border: 1px solid transparent;
  border-radius: 15px;
  font-size: 2rem;
  padding: 1.3rem;
}

textarea#message {
    width: 100%!important;
    border-top: 1px solid transparent;
    border-radius: 15px 15px 0px 15px;
    font-size: 1.1rem;
    padding: 1.3rem;
    display:flex;
    margin: auto;
    height: 200px;
}

#submit-btn {
    padding: 1rem 1.5rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
    background-color: var(--background-grey);
    color: var(--color-nice-white);
    border: 1px solid;
    font-weight: 600;
    z-index: 1;
    transition: 0.5s ease-in-out;
}

#submit-btn:hover, #submit-btn:focus {
    background-color: var(--color-btn-primary);
}

.contact_page-form  {
    height: 100vh;
    width: 100%;
    padding: 0em;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding-right: 9%;
    padding-top: 40px;
  }


  /* Media Queries */
@media(max-width: 768px) {
  form{
    padding: 1rem;
  }
  form > .col {
    width: 100%;
  }
  .contact_page-form {
    padding-right: 0;
    overflow: auto;
  }
}